import React from "react"

import TopSection from "../../../components/top-section"

const TopBullets = props => {
	return (
		<TopSection>
			<h3 data-aos="fade-up" data-aos-delay="100">In under 30 minutes:</h3>
			<p className="title" data-aos="fade-up" data-aos-delay="200">Improve the patient experience</p>
			<p className="title" data-aos="fade-up" data-aos-delay="300">Minimize loss-to-care and callbacks</p>
			<p className="title" data-aos="fade-up" data-aos-delay="400">Expedite partner treatment</p>
			<p data-aos="fade-up" data-aos-delay="400">Testing with Visby Medical represents an untapped growth 
			opportunity for your urgent care. Instrument-free means: no long-term commitment, no maintenance, 
			no capital expense, and ability to scale. Reduce the burden of follow-up by providing PCR 
			results to patients within minutes instead of days.</p>
		</TopSection>
	)
}
export default TopBullets
