import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "../utils/breakpoints/"
import { colors } from "../utils/variables/"

// Icons
import Check from "../assets/icons/checkmark.svg"

const StyledTopBullets = styled.div`
	flex: 1 0 30%;
	max-width: 460px;
	min-width: 280px;
	width: 100%;
	color: ${colors.charcoal};
	margin-left: 0;

	${breakpoint.small`
		max-width: 550px;
		margin-left: 3%;
	`}

	${breakpoint.large`
		margin-left: 1%;
	`}

	${breakpoint.extraLarge`
		margin-left: 0;
	`}

	.title {
		text-align: center;
		font-weight: 700;
		margin-bottom: 20px;
		padding: 0;
		font-size: 19px;
		line-height: 1.3em;
		color: ${colors.purpleDark};
		display: block;
		
		${breakpoint.small`
			font-size: 20px;
			display: block;
			text-align: left;
			margin-bottom: 25px;
			font-size: 21px;
			display: inline-flex;
			`}
		
		&::before {
            content: url(${Check});
            margin-right: 8px;

			${breakpoint.small`
				margin-right: 12px;
				margin-top: 3px;
				clear: both;
				float: left;
			`}
		}
	}

  	
	p {
		font-size: 19px;
		line-height: 1.6em;
		padding-bottom: 20px;
		margin: 0 20px;
		text-align: center;
  
		${breakpoint.small`
			margin-left: 0;
			text-align: left;
			padding-top: 10px;
		`}
	}
  	
	h3 {
		font-size: 26px;
		margin-bottom: 24px;
		text-align: center;

		${breakpoint.small`
			text-align: left;
		`}
	}


    a {
      color: ${colors.orange};

      &:hover {
        color: ${colors.purpleDark};
      }
    }
    
	.questions {
		font-weight: 700; 
		text-align: center;
		width: 90%;
		line-height: 1.4em;

		${breakpoint.small`
			text-align: left;
			width: 80%;
		`}
  	}

	  .references {
		font-size: 10px;
	}

`


const TopBullets = props => {
	return (
		<StyledTopBullets>
      {props.children}
			<p className="questions" data-aos="fade-up" data-aos-delay="200"> Schedule a 5-minute introductory call today at 1-833-GoVisby (<a href="tel:833-468-4729">1-833-468-4729</a>).</p>
		</StyledTopBullets>
	)
}
export default TopBullets
